import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class WaecFileManagement{
	apiUrl = baseUrl

	async fetchSubject () {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/waec-load-subject`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/waec-create-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/waec-update-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/waec-delete-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/waec-create-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/waec-update-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/waec-delete-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchPastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/load-waec-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchSinglePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/load-single-waec-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async storePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/store-waec-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updatePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/update-waec-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deletePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-waec-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchTheoryPastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/load-waec-theory-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchSingleTheoryPastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/load-single-waec-theory-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async storeTheoryPastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/store-waec-theory-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateTheoryPastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/update-waec-theory-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteTheoryPastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-waec-theory-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
}

const waecFileMgt = new WaecFileManagement()
export { waecFileMgt }